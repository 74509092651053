import config from './config/student'
import axios from '@/utils/request'
export const students = params => axios.post(config.students, params)
export const studentRemove = params => axios.post(config.studentRemove, params)
export const studentCreate = params => axios.post(config.studentCreate, params)
export const studentInfo = params => axios.post(config.studentInfo, params)
export const studentUpdate = params => axios.post(config.studentUpdate, params)
export const studentImport = params =>
  axios.post(config.studentImport, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const studentTemplate = params =>
  axios.post(config.studentTemplate, params, {
    responseType: 'blob',
  })
export const studentInvite = params => axios.post(config.studentInvite, params)
