<template>
  <div class="student-list">
    <table-list
      title="教务列表"
      :loading="loading"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <StudentInvite ref="StudentInvite" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加教务',
    method: _this.handleAdd,
  },
  {
    label: '批量添加教务',
    method: _this.handleBatchAdd,
  },
  // {
  //   label: '邀请教务',
  //   method: _this.handleInvite,
  // },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '教务姓名/手机号',
    prop: 'keyword',
  },
  {
    label: '身份证/护照',
    prop: 'cardNo',
  },
  // {
  //   label: '筛选部门',
  //   type: 'select',
  //   prop: 'deptId',
  //   children: _this.deptList.map(item => ({ value: item.deptId, label: item.deptName })),
  // },
  // {
  //   label: '班级筛选',
  //   type: 'select',
  //   prop: 'classId',
  //   children: _this.classList.map(item => ({ value: item.classId, label: item.className })),
  // },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '教务姓名',
  },
  {
    prop: 'mobile',
    label: '手机号',
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
  },
  // {
  //   prop: 'deptInfo',
  //   label: '所属部门',
  //   formatter: row => {
  //     let nameArray = row.deptInfo.map(element => element.deptName)
  //     return nameArray.join('/')
  //   },
  // },
  // {
  //   prop: 'classInfo',
  //   label: '所在班级',
  //   width: 300,
  //   formatter: row => {
  //     let nameArray = row.classInfo.map(element => element.className)
  //     return nameArray.join('/')
  //   },
  // },
  {
    prop: 'registerTime',
    label: '注册时间',
  },
  {
    prop: 'lastLoginTime',
    label: '最后一次登录时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import StudentInvite from './components/StudentInvite'
import { educationalList, educationalRemove } from '@/api/course'
import to from 'await-to'
export default {
  name: 'StudentList',
  components: {
    TableList,
    StudentInvite,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      studentList: [],
      selectStudents: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getStudents()
  },
  methods: {
    async getStudents() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(educationalList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getStudents()
    },
    handleAdd() {
      this.$router.push('/college/educational/add')
    },
    handleBatchAdd() {
      this.$router.push('/college/educational/batchAdd')
    },
    // 编辑
    handleEdit({ studentId }) {
      this.$router.push(`/college/educational/edit/${studentId}`)
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的教务')
      let studentIds = []
      this.selectStudents.map(item => studentIds.push(item.studentId))
      this.$confirm('确认删除教务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(educationalRemove({ studentIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getStudents()
      })
    },
    // 邀请
    handleInvite() {
      this.$refs['StudentInvite'].show()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getStudents()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getStudents()
    },
  },
}
</script>
